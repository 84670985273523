<template>
	<!-- 开发-合同情况-->
<div>
	<div class="container">
		<GModule class="g1" style="margin-bottom: 10px;">
			<GTitle name="合同基本情况(原始任务)"></GTitle>
			<ul class="money">
				<li>
					<p>合同金额</p>
					<p>{{info.contract_money}}</p>
				</li>
				<li>
					<p>完成天数</p>
					<p>{{info.completion_days}}</p>
				</li>
				<li>
					<p>合计工时</p>
					<p>{{info.total_hour}}</p>
				</li>
				<li>
					<p>时薪</p>
					<p>{{info.hourly}}</p>
				</li>
				<li>
					<p>待付金额</p>
					<p>{{info.ori_total_amount}}</p>
				</li>
				<li>
					<p>签约时间</p>
					<p>{{info.createtime_text}}</p>
				</li>
			</ul>
			<a-table
				class="ttable" 
				:columns="columnsHe" 
				:data-source="data" 
				:scroll="{ x: 500}" 
				bordered
				:pagination="false">
			  <template #bodyCell="{ column ,record}">
				  <template v-if="column.title === '付款凭证' && record.images">
				  	<div class="image-w-h">
				  		<a-image :preview="false" :width="120" :src="record.imgArr[0]" @click="openSwiper(record.imgArr)"/>
				  	</div>
				  </template>
			  </template>
			</a-table>
		</GModule>	
		<GModule class="g2">
			<GTitle name="新增/变更情况"></GTitle>
			<div class="time-box">
				<p>剩余免费工时：</p>
				<p>{{info.last_hour || 0}}</p>
			</div>	
			<a-table
				class="ttable" 
				sticky 
				:columns="columnsHe2" 
				:data-source="data2" 
				:scroll="{ x: 500}" 
				bordered
				:pagination="false">
			  <template #bodyCell="{ column ,record}">
				  <template v-if="column.title === '时薪'">
				  	{{info.hourly}}
				  </template>
				  <template v-if="column.title === '本期价格'">
				  	{{record.thisPrice}}
				  </template>
				  <template v-if="column.title === '付款凭证' && record.images">
				  	<div class="image-w-h">
				  		<a-image :preview="false" :width="120" :src="record.imgArr[0]" @click="openSwiper(record.imgArr)"/>
				  	</div>
				  </template>
			  </template>
			</a-table>
		</GModule>	
	</div>
</div>		
</template>

<script>
	export default{
		data(){
			return{
				columnsHe: [{
				    title: '期数',
				    width: 100,
				    dataIndex: 'periods',
				    fixed: 'left'
				  }, {
				    title: '应付比例（%）',
				    width: 100,
				    dataIndex: 'pay_ratio'
				  }, {
				    title: '应付金额',
				    width: 100,
				    dataIndex: 'pay_money'
				  }, {
				    title: '已付金额',
				    width: 120,
				    dataIndex: 'paid'
				  }, {
				    title: '待付款金额',
				    width: 120,
				    dataIndex: 'to_paid'
				  }, {
				    title: '状态',
				    width: 100,
				    dataIndex: 'status_text'
				  }, {
				    title: '付款凭证',
				    width: 120,
				    dataIndex: 'images'
				  }, {
				    title: '付款时间',
				    width: 120,
				    dataIndex: 'pay_time'
				  }],
				data: [],
				columnsHe2: [{
				    title: '期数',
				    width: 100,
				    dataIndex: 'periods',
				    fixed: 'left'
				  }, {
				    title: '时薪',
				    width: 100,
				    dataIndex: 'hourly'
				  }, {
				    title: '合计工时',
				    width: 100,
				    dataIndex: 'total_hour'
				  }, {
				    title: '消耗免费工时',
				    width: 120,
				    dataIndex: 'free_hour'
				  }, {
				    title: '本期价格',
				    width: 120,
				    dataIndex: 'to_paid'
				  }, {
				    title: '应付金额',
				    width: 100,
				    dataIndex: 'pay_money'
				  }, {
				    title: '已付金额',
				    width: 120,
				    dataIndex: 'paid'
				  }, {
				    title: '状态',
				    width: 120,
				    dataIndex: 'status_text'
				  }, {
				    title: '付款凭证',
				    width: 120,
				    dataIndex: 'images'
				  }, {
				    title: '付款时间',
				    width: 120,
				    dataIndex: 'pay_time'
				  }],
				data2:[],
				info:{} 
			}
		},
		computed:{
			proId(){
				return this.$store.state.proId  
			},
		},
		watch:{
			proId(val,oldvalue){
				if(val){
					this.getDetail()
				}
			}
		},
		async created() {
			this.getDetail()
		},
		methods:{
			async getDetail(){
				let res = await this.$utils.api.get({
					url:'/contract/contract_info_d',
					result:1
				})
				if(res.code == 1){
					if(res.data  == null){
						return
					}
					// 原始需求
					let a = res.data.originals
					for(let i in a){
						if(a[i].images != null && a[i].images.length > 0){
							a[i].imgArr = a[i].images.toString().split(',')
						}else{
							a[i].imgArr=[]
						}
					}
					this.data = a
					
					// 新增变更需求
					let b = res.data.appends
					for(let i in b){
						if(b[i].images != null && b[i].images.length > 0){
							b[i].imgArr = b[i].images.toString().split(',')
						}else{
							b[i].imgArr=[]
						}
						let m = res.data.hourly * b[i].total_hour
						b[i].thisPrice = m.toFixed(2)
					}
					this.data2 = b
					this.info = res.data
				}
			},
			openSwiper(arr){
				// 弹出图片列表
				this.$store.commit('setSwiperArr',arr)
				this.$store.commit('setSwiper',1)
			},
		}
	}
</script>

<style scoped lang="scss">
	.container{
		margin: 20px;
		overflow-x: hidden;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 0px; // 控制滚动条
		}
		&::-webkit-scrollbar-thumb{
			background-color: #bfbfbf; // 滚动条颜色
		}
		&:hover{
			&::-webkit-scrollbar {
				width: 1px;
			}
		}
		.state-box{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 5px;
		}
		.time-box{
			display: flex;
			align-items: center;
			p{
				&:nth-child(1){
					margin-right: 15px;
				}
				&:nth-child(2){
					width: 80px;
					font-size: 12px;
					color: #999999;
					border: 1px solid #ddd;
					border-radius: 5px;
					padding: 5px 10px;
				}
			}
		}
		.money{
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			background-color: #f5f5f5;
			margin-bottom: 15px;
			li{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				flex: 1;
				padding: 20px 0;
				p{
					margin: 0;
					padding: 0;
					&:nth-child(1){
						margin-bottom: 10px;
					}
				}
			}
		}
	}
</style>